export default function ValidarForm(valores) {
  let errores = {};


 

  //validar el nombre del usuario
  if (!valores.cedula) {
    errores.cedula = "La cédula es obligatoria";
  } else {
    let ced = valores.cedula;

    let c = ced.replace(/-/g, "");
    let cedul = c.substr(0, c.length - 1);
    let verificador = c.substr(c.length - 1, 1);
    let suma = 0;
    //let cedulaValida = 0;

    
    if (ced.length < 11 || ced.length > 13) {
      //console.log("false");
      errores.cedula = "Cedula no válida";
    }
    for (let i = 0; i < cedul.length; i++) {
      let mod = "";
       // eslint-disable-next-line
      if (i % 2 == 0) {
        mod = 1;
      } else {
        mod = 2;
      }
      let res = cedul.substr(i, 1) * mod;
      if (res > 9) {
        res = res.toString();
        let uno = res.substr(0, 1);
        let dos = res.substr(1, 1);
         // eslint-disable-next-line
        res = eval(uno) + eval(dos);
      }
 // eslint-disable-next-line
      suma += eval(res);
    }
    let el_numero = (10 - (suma % 10)) % 10;
     // eslint-disable-next-line
    if (el_numero == verificador && cedul.substr(0, 3) != "000") {
      //cedulaValida = 1;
      //errores.cedula = "ok";
    } else {
      //cedulaValida = 0;
      errores.cedula = "Cédula no válida";
    }
  }

  // //validar el nombre del usuario
  if (!valores.name) {
    errores.name = "El Nombre es obligatorio";
  }

  // //validar el nombre del usuario
  if (!valores.lastName) {
    errores.lastName = "El Apellido es obligatorio";
  }

  // //validar el nombre del usuario
  if (!valores.gender) {
    errores.gender = "El género es obligatorio";
  }




  if (!valores.cel) {
    errores.cel = "Tu Teléfono o móvil es obligatorio";
  }

  if (!valores.email) {
    errores.email = "Tu Correo electrónico es obligatorio";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(valores.email)) {
    errores.email = "tu Correo electrónico no valido";
  }



   
  if (!valores.province) {
    errores.province = "Tu Provincia es obligatoria";
  }else{

    if(valores.province === "Distrito Nacional"){
      //console.log('No tiene municipio')
    }
   else if (!valores.municipalities) {
      errores.municipalities = "Tu Municipio es obligatorio";
    }
  
  }





  // }else if( !/^(ftp|http|https):\/\/[^ "]+$/.test(valores.wa) ) {
  //     errores.wa = "URL mal formateada o no válida"
  // }

  return errores;
}
