import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom'


import NotFound from './pages/NotFound';
import InscStudent from './pages/InscStudent';

//Context


import './App.css';



 

const App = () => {
  return (

   <>
     <BrowserRouter>
    
        <Routes>  
          <Route path='/InscStudent/:Id' element={<InscStudent />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      
      </BrowserRouter>
    </>
   
  );
}

export default App;
