import React from "react";

const Header = () => {
  ////
  return (
    <>
      <div style={{ background: "#93b635" }} className="container-fluid">
        <div className="row text-center">
          <div className="col  mt-1">
            <img
              style={{ width: "110px", height: "100px" }}
              className="W-50"
              alt="TSE"
              src="../img/TSE.png"
            />
          </div>
        </div>
        <div className="row justify-content-center p-1">
          
          <div className="col">
            <h2
              className="text-center"
              style={{
                fontFamily: "times New Roman",
                fontWeight: "bold",
                color: "#ffffff",
              }}
            >
              Tribunal Superior Electoral
              <div style={{ color: "darkblue", fontWeight: "400px" }}>
                <h4>
                  Centro de Investigación y Capacitación de Justicia Electoral y
                  Democracia (CICJED).
                </h4>
               
              </div>
            </h2>
          </div>

          
        </div>
      </div>
    </>
  );
};

export default Header;
