import React, { useState, useEffect } from "react";
import axios from "axios";

import Swal from "sweetalert2";
import Layout from '../components/layout/Header'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom';
import bcrypt from 'bcryptjs'

//import conexion a DB



//Validar formulario
import ValidarForm from "../validacion/ValidarForm";
//import { route } from "next/dist/next-server/server/router";

const InscStudent = () => {
  ////
  /////
  ////
  ////

  const params = useParams()




  const dbRD = [
    {
      id: "1",
      nombre: "Santo Domingo",
      municipios: [
        { idm: "1", nombreM: "BOCA CHICA" },
        { idm: "2", nombreM: "LOS ALCARRIZOS" },
        { idm: "3", nombreM: "PEDRO BRAND" },
        { idm: "4", nombreM: "SAN ANTONIO DE GUERRA" },
        { idm: "5", nombreM: "SANTO DOMINGO ESTE" },
        { idm: "6", nombreM: "SANTO DOMINGO NORTE" },
        { idm: "6", nombreM: "SANTO DOMINGO OESTE" },
      ],
    },
    { id: "2", nombre: "Distrito Nacional", municipios: [] },
    {
      id: "3",
      nombre: "Santiago",
      municipios: [
        { idm: "1", nombreM: "BAITOA" },
        { idm: "2", nombreM: "JANICO" },
        { idm: "3", nombreM: "LICEY AL MEDIO" },
        { idm: "4", nombreM: "PUÑAL" },
        { idm: "5", nombreM: "SABANA IGLESIA" },
        { idm: "6", nombreM: "SAN JOSE DE LAS MATAS" },
        { idm: "7", nombreM: "SANTIAGO DE LOS CABALLEROS" },
        { idm: "8", nombreM: "TAMBORIL" },
        { idm: "9", nombreM: "VILLA BISONO -NAVARRETE-" },
        { idm: "10", nombreM: "VILLA GONZALEZ" },
      ],
    },
    {
      id: "4",
      nombre: "San Cristóbal",
      municipios: [
        { idm: "1", nombreM: "BAJOS DE HAINA" },
        { idm: "2", nombreM: "CAMBITA GARABITOS" },
        { idm: "3", nombreM: "LOS CACAOS" },
        { idm: "4", nombreM: "SABANA GRANDE DE PALENQUE" },
        { idm: "5", nombreM: "SAN CRISTOBAL" },
        { idm: "6", nombreM: "SAN GREGORIO DE NIGUA" },
        { idm: "7", nombreM: "VILLA ALTAGRACIA" },
        { idm: "8", nombreM: "YAGUATE" },
      ],
    },
    {
      id: "5",
      nombre: "La Vega",
      municipios: [
        { idm: "1", nombreM: "CONSTANZA" },
        { idm: "2", nombreM: "JARABACOA" },
        { idm: "3", nombreM: "JIMA ABAJO" },
        { idm: "4", nombreM: "LA VEGA" },
      ],
    },
    {
      id: "6",
      nombre: "Puerto Plata",
      municipios: [
        { idm: "1", nombreM: "ALTAMIRA" },
        { idm: "2", nombreM: "GUANANICO" },
        { idm: "3", nombreM: "IMBERT" },
        { idm: "4", nombreM: "LOS HIDALGOS" },
        { idm: "5", nombreM: "LUPERON" },
        { idm: "6", nombreM: "PUERTO PLATA" },
        { idm: "7", nombreM: "SOSUA" },
        { idm: "8", nombreM: "VILLA ISABELA" },
        { idm: "9", nombreM: "VILLA MONTELLANO" },
      ],
    },
    {
      id: "7",
      nombre: "Duarte",
      municipios: [
        { idm: "1", nombreM: "ARENOSO" },
        { idm: "2", nombreM: "CASTILLO" },
        { idm: "3", nombreM: "EUGENIO MARIA DE HOSTOS" },
        { idm: "4", nombreM: "LAS GUARANAS" },
        { idm: "5", nombreM: "PIMENTEL" },
        { idm: "6", nombreM: "SAN FRANCISCO DE MACORIS" },
        { idm: "7", nombreM: "VILLA RIVA" },
      ],
    },
    {
      id: "8",
      nombre: "La Altagracia",
      municipios: [
        { idm: "1", nombreM: "HIGUEY" },
        { idm: "2", nombreM: "SAN RAFAEL DEL YUMA" },
      ],
    },
    {
      id: "9",
      nombre: "La Romana",
      municipios: [
        { idm: "1", nombreM: "GUAYMATE" },
        { idm: "2", nombreM: "LA ROMANA" },
        { idm: "3", nombreM: "VILLA HERMOSA" },
      ],
    },
    {
      id: "10",
      nombre: "San Juan",
      municipios: [
        { idm: "1", nombreM: "BOHECHIO" },
        { idm: "2", nombreM: "EL CERCADO" },
        { idm: "3", nombreM: "JUAN DE HERRERA" },
        { idm: "4", nombreM: "LAS MATAS DE FARFAN" },
        { idm: "5", nombreM: "SAN JUAN DE LA MAGUANA" },
        { idm: "6", nombreM: "VALLEJUELO" },
      ],
    },
    {
      id: "11",
      nombre: "Espaillat",
      municipios: [
        { idm: "1", nombreM: "CAYETANO GERMOSEN" },
        { idm: "2", nombreM: "GASPAR HERNANDEZ" },
        { idm: "3", nombreM: "JAMAO AL NORTE" },
        { idm: "4", nombreM: "MOCA" },
        { idm: "5", nombreM: "SAN VICTOR" },
      ],
    },
    {
      id: "12",
      nombre: "Azua",
      municipios: [
        { idm: "1", nombreM: "AZUA" },
        { idm: "2", nombreM: "ESTEBANIA" },
        { idm: "3", nombreM: "GUAYABAL" },
        { idm: "4", nombreM: "LAS CHARCAS" },
        { idm: "5", nombreM: "LAS YAYAS DE VIAJAMA" },
        { idm: "6", nombreM: "PADRE LAS CASAS" },
        { idm: "7", nombreM: "PERALTA" },
        { idm: "8", nombreM: "PUEBLO VIEJO" },
        { idm: "9", nombreM: "SABANA YEGUA" },
        { idm: "10", nombreM: "TABARA ARRIBA" },
      ],
    },
    {
      id: "13",
      nombre: "Barahona",
      municipios: [
        { idm: "1", nombreM: "BARAHONA" },
        { idm: "2", nombreM: "CABRAL" },
        { idm: "3", nombreM: "EL PEÑON" },
        { idm: "4", nombreM: "ENRIQUILLO" },
        { idm: "5", nombreM: "FUNDACION" },
        { idm: "6", nombreM: "JAQUIMEYES" },
        { idm: "7", nombreM: "LA CIENAGA" },
        { idm: "8", nombreM: "LAS SALINAS" },
        { idm: "9", nombreM: "PARAISO" },
        { idm: "10", nombreM: "POLO" },
        { idm: "11", nombreM: "VICENTE NOBLE" },
      ],
    },
    {
      id: "14",
      nombre: "Monte Plata",
      municipios: [
        { idm: "1", nombreM: "BAYAGUANA" },
        { idm: "2", nombreM: "MONTE PLATA" },
        { idm: "3", nombreM: "PERALVILLO" },
        { idm: "4", nombreM: "SABANA GRANDE DE BOYA" },
        { idm: "5", nombreM: "YAMASA" },
      ],
    },
    {
      id: "15",
      nombre: "Peravia",
      municipios: [
        { idm: "1", nombreM: "BANI" },
        { idm: "2", nombreM: "MATANZAS" },
        { idm: "3", nombreM: "NIZAO" },
      ],
    },
    {
      id: "16",
      nombre: "Monseñor Nouel",
      municipios: [
        { idm: "1", nombreM: "BONAO" },
        { idm: "2", nombreM: "MAIMON" },
        { idm: "3", nombreM: "PIEDRA BLANCA" },
      ],
    },
    {
      id: "17",
      nombre: "Valverde",
      municipios: [
        { idm: "1", nombreM: "ESPERANZA" },
        { idm: "2", nombreM: "LAGUNA SALADA" },
        { idm: "3", nombreM: "MAO" },
      ],
    },
    {
      id: "18",
      nombre: "Sánchez Ramírez",
      municipios: [
        { idm: "1", nombreM: "CEVICOS" },
        { idm: "2", nombreM: "COTUI" },
        { idm: "3", nombreM: "FANTINO" },
        { idm: "4", nombreM: "VILLA LA MATA" },
      ],
    },
    {
      id: "19",
      nombre: "María Trinidad Sánchez",
      municipios: [
        { idm: "1", nombreM: "CABRERA" },
        { idm: "2", nombreM: "EL FACTOR" },
        { idm: "3", nombreM: "NAGUA" },
        { idm: "4", nombreM: "RIO SAN JUAN" },
      ],
    },
    {
      id: "20",
      nombre: "Montecristi",
      municipios: [
        { idm: "1", nombreM: "CASTAÑUELAS" },
        { idm: "2", nombreM: "GUAYUBIN" },
        { idm: "3", nombreM: "LAS MATAS DE SANTA CRUZ" },
        { idm: "4", nombreM: "MONTECRISTI" },
        { idm: "5", nombreM: "PEPILLO SALCEDO" },
        { idm: "6", nombreM: "VILLA VASQUEZ" },
      ],
    },
    {
      id: "21",
      nombre: "Samaná",
      municipios: [
        { idm: "1", nombreM: "LAS TERRENAS" },
        { idm: "2", nombreM: "SAMANA" },
        { idm: "3", nombreM: "SANCHEZ" },
      ],
    },
    {
      id: "22",
      nombre: "Bahoruco",
      municipios: [
        { idm: "1", nombreM: "GALVAN" },
        { idm: "2", nombreM: "LOS RIOS" },
        { idm: "3", nombreM: "NEYBA" },
        { idm: "4", nombreM: "TAMAYO" },
        { idm: "5", nombreM: "VILLA JARAGUA" },
      ],
    },
    {
      id: "23",
      nombre: "Hermanas Mirabal",
      municipios: [
        { idm: "1", nombreM: "SALCEDO" },
        { idm: "2", nombreM: "TENARES" },
        { idm: "3", nombreM: "VILLA TAPIA" },
      ],
    },
    {
      id: "24",
      nombre: "El Seibo",
      municipios: [
        { idm: "1", nombreM: "EL SEIBO" },
        { idm: "2", nombreM: "MICHES" },
      ],
    },
    {
      id: "25",
      nombre: "Hato Mayor",
      municipios: [
        { idm: "1", nombreM: "EL VALLE" },
        { idm: "2", nombreM: "HATO MAYOR" },
        { idm: "3", nombreM: "SABANA DE LA MAR" },
      ],
    },
    {
      id: "26",
      nombre: "Dajabón",
      municipios: [
        { idm: "1", nombreM: "DAJABON" },
        { idm: "2", nombreM: "EL PINO" },
        { idm: "3", nombreM: "LOMA DE CABRERA" },
        { idm: "4", nombreM: "PARTIDO" },
        { idm: "5", nombreM: "RESTAURACION" },
      ],
    },
    {
      id: "27",
      nombre: "Elías Piña",
      municipios: [
        { idm: "1", nombreM: "BANICA" },
        { idm: "2", nombreM: "COMENDADOR" },
        { idm: "3", nombreM: "EL LLANO" },
        { idm: "4", nombreM: "HONDO VALLE" },
        { idm: "5", nombreM: "JUAN SANTIAGO" },
        { idm: "6", nombreM: "PEDRO SANTANA" },
      ],
    },
    {
      id: "28",
      nombre: "San José de Ocoa",
      municipios: [
        { idm: "1", nombreM: "RANCHO ARRIBA" },
        { idm: "2", nombreM: "SABANA LARGA" },
        { idm: "3", nombreM: "SAN JOSE DE OCOA" },
      ],
    },
    {
      id: "29",
      nombre: "Santiago Rodríguez",
      municipios: [
        { idm: "1", nombreM: "MONCION" },
        { idm: "2", nombreM: "SAN IGNACIO DE SABANETA" },
        { idm: "3", nombreM: "VILLA LOS ALMACIGOS" },
      ],
    },
    {
      id: "30",
      nombre: "Independencia",
      municipios: [
        { idm: "1", nombreM: "CRISTOBAL" },
        { idm: "2", nombreM: "DUVERGE" },
        { idm: "3", nombreM: "JIMANI" },
        { idm: "4", nombreM: "LA DESCUBIERTA" },
        { idm: "5", nombreM: "MELLA" },
        { idm: "6", nombreM: "POSTRER RIO" },
      ],
    },
    {
      id: "31",
      nombre: "Pedernales",
      municipios: [
        { idm: "1", nombreM: "OVIEDO" },
        { idm: "2", nombreM: "PEDERNALES" },
      ],
    },
    {
      id: "32",
      nombre: "San Pedro de Macoris",
      municipios: [
        { idm: "1", nombreM: "CONSUELO" },
        { idm: "2", nombreM: "GUAYACANES" },
        { idm: "3", nombreM: "LOS LLANOS" },
        { idm: "4", nombreM: "QUISQUEYA" },
        { idm: "5", nombreM: "RAMON SANTANA" },
        { idm: "6", nombreM: "SAN PEDRO DE MACORIS" },
      ],
    },
  ];


  //context con las operaciones crud
  // const { firebase } = useContext(FirebaseContext);



  //State
  const [datos, guardarDatos] = useState({
    //
    cedula: "",
    name: "",
    lastName: "",
    gender: "",
    cel: "",
    email: "",
    province: "",
    municipalities: "",
    idGrade: ""
  });

  //state
  //const [error, guardarError] = useState(false);
  const [errores, guardarErrores] = useState(false);
  //state
  const [mlist, guardarMlist] = useState([]);

  const [query, setQuery] = useState(false)

  const [title, setTitle] = useState(false)

  const [tk, setTK] = useState();

  //const [comprob , guardarComprob]= useState([])

  //destructuring
  const {
    cedula,
    name,
    lastName,
    gender,
    cel,
    email,
    province,
    municipalities

  } = datos;


  useEffect(() => {




    
  


    const keysP = '@#$' + process.env.REACT_APP_NOT_SECRET_CODE + '@#$'


 


    if(query === false){


      
      encriptarSessionWeb(keysP)
      // .then(()=>{
        

      //  consultar()
        
      // })
      
    
      setQuery(true)
    }
    




    
    
   
 









    dbRD.forEach((element) => {
      if (element.nombre === province) {
     

        guardarMlist(element.municipios);
      }
    });
    // eslint-disable-next-line
  }, [province])









  ///
  ////
  /////       Function
  ////

    //para encriptar
  const encriptarSessionWeb = async (seedSession) => {

      const salt = await bcrypt.genSalt(10);

      const HASH = await bcrypt.hash(seedSession, salt)

      
      const headers = {

        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
        'Content-Type': 'application/json'
      }

      const resultado = await axios.post(process.env.REACT_APP_BASE_URL+"/4p1/4uth3nt1c4t10n", { HASH },
        {
          headers: headers
        });

        let pv= await resultado.data.token
     

        setTK(pv)
        
        consultar(pv)
      
   



  }


  const consultar = async (pv) => {

      let tk = pv
      let NumR = params.Id;


      

      const headers = {

        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
        'Content-Type': 'application/json',
        'Authorization': tk
      }

      const resultado = await axios.post(process.env.REACT_APP_BASE_URL+"/4p1/SeeGr4d322", { NumR },
        {
          headers: headers
        })


      let info = await resultado.data[0]


      //guardarDatos(info)


     


      setTitle(info.title)


      //setRegistros(resultado.data)




    


  }

  const onInputChange = (name) => (e) => {
    guardarDatos({
      ...datos,
      [name]: e.target.value,
    });

    
  };


  //for to send

  const onSubmitForm = async (e) => {
    //prevent reload
    e.preventDefault();

  

    const fecha = Date.now();

    let fechaR = format((fecha), 'dd/MM/yyyy hh:mm:ss a')

    //add fecha
    const objDatos = {
      cedula,
      name,
      lastName,
      gender,
      cel,
      email,
      province,
      municipalities,
      fechaR,
      idGrade: params.Id
    
    }





    //array for errors
    const noErrores = Object.keys(errores).length === 0;

  

    if (noErrores) {
    

      if (cedula !== "") {
        //post;


        const headers = {

          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
          'Content-Type': 'application/json',
          'Authorization': tk
        }

        const resultado = await axios.post(process.env.REACT_APP_BASE_URL+"/4p1/N3wSt0d3nt2", { objDatos },
          {
            headers: headers
          });



        if (parseInt(resultado.data) === 200) {
          Swal.fire({
            title: "LISTO",
            text: `Usted ha completado su solicitud satisfactoriamente, le estaremos informando en caso de ser admitido.`,
            footer:
              '<p class="text-center">Centro de Investigación y Capacitación de Justicia Electoral y Democracia (CICJED).</p>',
            icon: "success",
          });

        

          guardarDatos({
            cedula: "",
            name: "",
            lastName: "",
            gender: "",
            cel: "",
            email: "",

          });


         

        } else {
          Swal.fire({
            title: "Error",
            text: "Inscripción Cerrada.",
            icon: "error",
          });
        }
      } else {
      
        return;
      }
    } else {
      console.log("tiene errores");
    }
  };


  //onblur
  const handleBlur = () => {
    const erroresValidacion = ValidarForm(datos);
    guardarErrores(erroresValidacion);
  };





  ////
  ///////
  ///////
  ///////

  return (
    <>

      <Layout />
      <form style={{ marginTop: "1rem" }}>

        <div>
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-sm-4 mb-5">
                <div className="card border-secondary position-static">
                  <div className="card-body">
                    <div className="text-center">
                      <h5>{title}</h5>
                    </div>
                    <div>
                      <h4
                        style={{
                          fontFamily: "times New Roman",
                          fontWeight: "bold",
                          color: "black",
                        }}
                        className="text-center"
                      >
                        Formulario de Admisión
                      </h4>
                    </div>


                    <div className="form-group">
                      <label>Cédula:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Escribe aquí"
                        name="cedula"
                        value={cedula}
                        onChange={onInputChange("cedula")}
                        onBlur={handleBlur}
                      />
                    </div>

                    {errores.cedula && (
                      <div className="msgError">{errores.cedula}</div>
                    )}

                    <div className="form-group">
                      <label>Nombre (s):</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Escribe aquí"
                        name="name"
                        value={name}
                        onChange={onInputChange("name")}
                        onBlur={handleBlur}
                      />
                    </div>

                    {errores.name && (
                      <div className="msgError">{errores.name}</div>
                    )}

                    <div className="form-group">
                      <label>Apellido (s):</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Escribe aquí"
                        name="lastName"
                        value={lastName}
                        onChange={onInputChange("lastName")}
                        onBlur={handleBlur}
                      />
                    </div>

                    {errores.lastName && (
                      <div className="msgError">{errores.lastName}</div>
                    )}

                    <label>Género:</label>
                    <div className="form-group">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="gender"
                          name="gender"
                          value="M"
                          checked={gender === "M"}
                          onChange={onInputChange("gender")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="gender"
                        >
                          Masculino
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="gender"
                          name="gender"
                          value="F"
                          checked={gender === "F"}
                          onChange={onInputChange("gender")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="gender"
                        >
                          Femenino
                        </label>
                      </div>
                    </div>

                    {errores.gender && (
                      <div className="msgError">{errores.gender}</div>
                    )}

                    <div className="form-group">
                      <label>Teléfono o Móvil:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Escribe aquí"
                        name="cel"
                        value={cel}
                        onChange={onInputChange("cel")}
                        onBlur={handleBlur}
                      />
                    </div>

                    {errores.cel && (
                      <div className="msgError">{errores.cel}</div>
                    )}



                    <div className="form-group">
                      <label>Correo electrónico:</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Escribe aquí"
                        name="email"
                        value={email}
                        onChange={onInputChange("email")}
                        onBlur={handleBlur}
                      />
                    </div>

                    {errores.email && (
                      <div className="msgError">{errores.email}</div>
                    )}

                    <div className="form-group">
                      <label htmlFor="exampleFormControlSelect1">
                        Provincia:
                      </label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        name="province"
                        value={province}
                        onChange={onInputChange("province")}
                        onBlur={handleBlur}
                      >
                        <option></option>
                        {dbRD.map((p) => (
                          <option key={p.id} value={p.nombre}>
                            {p.nombre.toUpperCase()}
                          </option>
                        ))}
                      </select>
                    </div>

                    {errores.province && (
                      <div className="msgError">{errores.province}</div>
                    )}

                    {mlist.length !== 0 && (
                      <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">
                          Municipio:
                        </label>
                        <select
                          className="form-control"
                          id="exampleFormControlSelect2"
                          name="municipalities"
                          value={municipalities}
                          onChange={onInputChange("municipalities")}
                          onBlur={handleBlur}
                        >
                          <option></option>
                          {mlist.map((p) => (
                            <option key={p.idm}>{p.nombreM}</option>
                          ))}
                        </select>
                      </div>
                    )}

                    {errores.municipalities && (
                      <div className="msgError">
                        {errores.municipalities}
                      </div>
                    )}


                    <div className="form-group">
                      <div  >
                        <input
                          type="button"
                          onClick={onSubmitForm}
                          className="btn btn-success btn-block "
                          value="Enviar"
                        />
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>
    </>
  );
};

export default InscStudent;
